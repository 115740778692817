<template>
  <div class="bg-white rounded-lg shadow-lg" @mouseleave="isHover = false" @mouseover="isHover = true">
    <div class="flex h-full items-center flex-col text-center py-4 px-11">
      <div>
        <div class="h-auto overflow-hidden w-[214px] h-[214px] rounded-2xl">
          <figure class="image w-[214px] h-[214px]">
            <NuxtLink :to="link">
              <img class="!w-[214px] !h-[214px] object-cover" :src="imageUrl" :alt="item.name" loading="lazy" />
            </NuxtLink>
          </figure>
        </div>
      </div>
      <div class="mb-4 grow">
        <NuxtLink :to="link" class="inline-flex text-xl font-bold my-4 hover:text-meclee-blue">{{ item.name }}</NuxtLink>
<!--        <p class="specialty">{{ localizedSpecializations }}</p>-->
        <p class="experience mb-3">{{ t('marketplace.catalog.experience', item.experienceInYears) }}</p>
        <p class="text-2xl mb-3 font-bold" v-if="!hidePrice">{{ formatCurrency(getMinimumPrice(item)) }}</p>
        <p>{{ t('marketplace.catalog.sessionTime') }}</p>
      </div>

      <NearestTimeSlot class="mb-5"
                       :class="{'rounded-full pt-4 pl-6 pr-6': item.nearestTimeSlot, 'pb-6': !item.nearestTimeSlot}"
                       :catalog-item="item"
                       :with-buttons="false"
                       :show-no-time-slots="false"
      />

      <div class="w-full min-h-[107px]">
        <slot name="buttons" :item="item" :is-checkout-enabled="Boolean(item.nearestTimeSlot)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {dateFormatter} from "@modules/time";
import {CatalogItem} from "../../../../../models/catalog";
import NearestTimeSlot from "./NearestTimeSlot.vue";
import {useCatalogFormatter} from "../composables/useCatalogFormatter";

interface itemProps {
  item: CatalogItem,
  link: string,
  hidePrice: boolean,
}

const props = defineProps<itemProps>();
const {t, locale} = useI18n();
const {formatSessionDateTime} = dateFormatter(locale.value);
const { formatCurrency } = useCurrency(locale.value);
const {getMinimumPrice} = useCatalogFormatter();

const isHover = ref(false);
const imageUrl = computed(() => {
  if (isHover.value && props.item.videoGifUrl) {
    return props.item.videoGifUrl;
  } else {
    return props.item.thumbnailUrl;
  }
})
</script>

<style scoped>

</style>
